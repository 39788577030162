* {
  font-family: Roboto, sans-serif !important;
  letter-spacing: 0.75px;
}
/* :root {
  font-size: 12px;
} */



.row {
  max-width: 120rem;
  margin: 0 auto;
}
.row:not(:last-child) {
  margin-bottom: 2.688rem;
}
.row::after {
  content: "";
  display: table;
  clear: both;
}
.row [class^="col-"] {
  float: left;
}
.row [class^="col-"]:not(:last-child) {
  margin-right: 6.625rem;
}
.row .col-1-of-2 {
  width: calc((100% - 2rem) / 2);
}
.row .col-1-of-3 {
  width: calc((100% - (2rem * 2)) / 3);
}
.row .col-1-of-4 {
  width: calc((100% - (2rem * 3)) / 4);
}
.row .col-2-of-3 {
  width: calc(2 * (100% - (2rem * 2)) / 3);
}
.row .col-2-of-4 {
  width: calc(2 * (100% - (2rem * 3)) / 4);
}
.row .col-3-of-4 {
  width: calc(3 * (100% - (2rem * 3)) / 4);
}


