.listTableEdit {
  height: auto;
  :global {
    .ant-table-thead > tr > th {
      background: #fff !important;
      white-space: nowrap;
    }
    .ant-table-tbody > tr > td {
      white-space: nowrap;
    }
  }
}

.tableForm__item {
  font-size: 12px !important;
}

:global(.ant-form-item) {
  margin-bottom: auto;
  font-size: 1rem;
}

:global(.ant-input-number-input) {
  font-size: 1rem;
}
