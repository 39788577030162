@import "./sass/main";

.App {
  margin: 0;
}

@media (max-width: 1920px) {
  html {
    font-size: 16px !important;
  }
}

@media (max-width: 1280px) {
  html {
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 8px !important;
  }
}

@media (max-width: 360px) {
  html {
    font-size: 6px !important;
  }
}
