// @import "../../sass/variables.scss";
@import "../../sass/main.scss";

.ev_btn {
  &__normal {
    padding: 0 20px;
    border-color: $button-normal;
    color: $button-normal;

    &:hover {
      color: $color-white;
      background-color: $button-normal;
      border-color: $button-normal;
    }

    &:active {
      color: $color-white;
      background-color: $button-normal;
      border-color: $button-normal;
    }

    &:focus {
      color: $color-white;
      background-color: $button-normal;
      border-color: $button-normal;
    }

    &:disabled {
      color: $neutral-300;
      pointer-events: none;
      background-color: $color-white;
      border-color: $neutral-300;
    }
  }

  &__submit {
    border: none;
    padding: 0 20px;
    color: $color-white;
    background-color: $button-submit;

    &:hover {
      color: $color-white;
      background-color: $hightlight-900;
      border-color: $hightlight-900;
    }

    &:active:focus {
      color: $color-white;
      background-color: $hightlight-900;
      border-color: $hightlight-900;
    }

    &:disabled {
      color: $color-white;
      pointer-events: none;
      background-color: $neutral-200;
      border-color: $neutral-200;
    }
  }

  &__cancel {
    border: 1px solid $neutral-500;
    color: $neutral-500;
    background-color: $color-white;
    padding: 0 20px;

    &:hover {
      color: $neutral-500;
      background-color: $neutral-200;
      border-color: $neutral-200;
    }

    &:active {
      color: $neutral-500;
      background-color: $neutral-200;
      border-color: $neutral-200;
    }

    &:focus {
      color: $neutral-500;
      background-color: $neutral-200;
      border-color: $neutral-200;
    }

    &:disabled {
      color: $neutral-300;
      pointer-events: none;
      background-color: $color-white;
      border-color: $neutral-300;
    }
  }

  &__medium {
    width: auto;
    font-size: 1rem;
    margin: 0 5px;
  }
  &__large {
    width: auto;
    max-width: 16rem;
    font-size: 1rem;
  }
}

.btn-text {
  text-transform: uppercase;
  vertical-align: middle;
}

.btn-icon {
  margin-right: 5px;
  vertical-align: middle;
}
