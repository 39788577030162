@import "../../../sass/main.scss";

.form {
  &__item {
    padding: 0px !important;
    margin-bottom: 10px !important;
    width: 17.67rem !important;

    :global(.ant-form-item-label > label) {
      font-style: normal;
      font-size: 0.75rem !important;
      font-weight: 500;
      text-transform: uppercase;
    }
    :global(.ant-form-item-label) {
      padding: 0;
    }

    &-input {
      font-size: 12px !important;
      font-weight: 600 !important;
      caret-color: $hightlight;
      border-radius: 5px !important;
      // color: $hightlight;
      width: 17.67rem !important;
      height: 2.67rem !important;

      :global(.ant-picker-input > input) {
        font-size: 12px !important;
        color: $hightlight;
      }
    }

    &-radio {
      font-size: 12px !important;
    }

    &-checkbox {
      font-size: 12px !important;
    }
    &-submit {
      margin-top: 30px !important;
    }
  }
  &-divider {
    margin-top: 40px;
    border-top: 1.5px solid rgba(0, 0, 0, 0.06);
    min-width: 70%;
    width: 70%;
  }
}

.icon {
  font-size: 10px;
}
