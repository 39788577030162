// @import "./base.scss";

/* Main Colors: */
$color-primary: $primary-700;
$color-secondary: #cdd5de;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

/* hightlight Colors */
$hightlight: $hightlight-800;

$button-normal: $hightlight-800;
$button-submit: $hightlight-800;

/* Neutral Colors */
$neutral: $neutral-800;

/* Table title Background */
$table-title-background: #e6eaee;
$selected: #f8f6ed;
$overlay-colors: #202020 65%;

/* Font/text values */
$default-font-size: 0.75rem;
$title-font-size: 0.875rem;

$font-size-small: 12px;
$font-size-middle: 14px;
$font-size-large: 16px;

$--unnamed-font-family-roboto: Roboto;
$--unnamed-font-family-noto-sans: Noto Sans;

// COLORS
/* Utility Colors */
$error: #e20d0d;

// grid
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-horizontal: 6rem;
