// @import "../../sass/variables.scss";
@import "../../sass/main.scss";

.user-layout-container {
  height: 100vh;
  background-image: radial-gradient(
    $primary-050,
    $primary-100,
    $primary-200,
    $primary-300,
    $primary-400,
    $primary-500,
    $primary-600
  );
  background-size: 100% !important;
  background-repeat: no-repeat;
  background-position: center;
}

.header {
  background: transparent !important;
}

.header .lang {
  float: right;
}

.content {
  flex: 1;
  margin: 15vh 20vw;
}
