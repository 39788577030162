/* Main Colors: */
$primary-900: #262e39;
$primary-800: #2d3844;
$primary-700: #354150;
$primary-600: #4b5b70;
$primary-500: #607690;
$primary-400: #7e91a9;
$primary-300: #9eadbe;
$primary-200: #bec8d4;
$primary-100: #eff1f4;
$primary-050: #eff1f4;

/* hightlight Colors */
$hightlight-900: #b16802;
$hightlight-800: #d07902;
$hightlight-700: #fa9303;
$hightlight-050: #eff1f4;

/* Neutral Colors */
$neutral-900: #111111;
$neutral-800: #202020;
$neutral-700: #414141;
$neutral-600: #616161;
$neutral-500: #808080;
$neutral-400: #a0a0a0;
$neutral-300: #c0c0c0;
$neutral-200: #dfdfdf;
$neutral-100: #efefef;
$neutral-50: #f8f8f8;

$subMenu-color: #cdd5de;
$subMenu-text-color: #3f4e5e;

$white: #fff;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
}
