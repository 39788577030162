// @import "../../sass/variables.scss";
@import "../../sass/main.scss";
.form {
  padding: 10px 50px;
  height: 100%;
  width: 100%;

  &-headerBox {
    height: 12vh;
    text-align: right;
    border-bottom: solid;
    margin-bottom: 20px;
    position: relative;
    border-bottom-color: $hightlight-800;

    &__text {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: $font-size-small;
      color: $neutral-500;
    }
  }

  &__item {
    padding: 0px !important;
    margin-bottom: 10px !important;

    :global(.ant-form-item-label > label) {
      font-size: 10px !important;
    }
    :global(.ant-form-item-label) {
      padding: 0;
    }

    &-input {
      width: 100%;
      font-size: 12px !important;
      border: none;
      box-shadow: none;
      border-radius: 0 !important;
      border-bottom: 1.5px solid rgba(107, 106, 106, 0.2) !important;
      color: $hightlight;
      :global(.ant-picker-input > input) {
        font-size: 12px !important;
        color: $hightlight;
      }

      :global(.ant-form label) {
        font-size: 12px !important;
      }
    }

    &-submit {
      margin-top: 30px !important;
    }
  }
}

.form-roleBox {
  height: 12vh;
}
.form-textBox {
  width: 100%;
  text-align: center;
  background-color: #fff;

  &__btn {
    border: none !important;
    text-transform: uppercase;
  }

  &__text {
    font-size: 1.167rem !important;
    display: inline-block;
    // &:active {
    //   border-bottom: 3px solid $hightlight !important;
    //   color: $hightlight !important;
    // }

    // &:focus {
    //   border-bottom: 3px solid $hightlight !important;
    //   color: $hightlight !important;
    // }
    // &:hover {
    //   border-bottom: 3px solid $hightlight !important;
    //   color: $hightlight !important;
    // }
  }
}


:global(.ant-radio-button-checked) {
  border-bottom: 2px solid $hightlight !important;
  color: $hightlight !important;
  width: 50% !important;
  left: 25% !important;
}
:global(.ant-radio-button-wrapper:focus-within) {
  box-shadow: none !important;
}
