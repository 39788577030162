// @import "../../sass/variables.scss";
@import "../../sass/main.scss";
.avatar {
  background-color: $color-primary;
  color: #fff;
  &-icon {
    color: $hightlight !important;
  }
}

.dropdown {
  &-user {
    background-color: $color-secondary !important;
    cursor: default;
  }
  &__logout {
    background-color: black;
    color: #fff;
    text-align: center;
    display: block;

    &:hover {
      background-color: rgba(0, 0, 0, 0.75);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.75);
    }
    &:focus {
      background-color: rgba(0, 0, 0, 0.75);
    }

    &-icon {
      vertical-align: middle;
    }
    &-text {
      margin-left: 3.5px;
      vertical-align: middle;
    }
  }
}

.usermenu {
  padding: 0;
  border-radius: 16px;
  width: 250px;
}

.description {
  &-changepass {
    color: #ca6e02 !important;
    &:hover {
      text-decoration: underline;
    }
  }
}
