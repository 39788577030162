.content {
  padding: 10px;
  height: calc(100vh - 64px);
  &-title {
    margin: 15px 0 !important;
    width: 100% !important;
  }

  &-table {
    font-weight: 600;
  }
}

.btngroup {
  padding: 5px !important;
  margin-bottom: 2px !important;
}

.custom_table {
  width: 100%;
  :global(.ant-table table) {
    font-size: 1rem;
  } 
}

.footer {
  font-size: 10px;
}