.page-content {
  background-color: #fff;
  height: 100vh;
}

.content {
  padding: 20px;
  height: calc(100% - 84px);
}

.layout {
  height: 100vh;
  overflow: "auto"
}
