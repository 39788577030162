// @import "./variables.scss";
.row {
  max-width: $grid-width;
  // background-color: #eee;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: $gutter-vertical;
  }

  @include clearfix;

  [class^="col-"] {
    // background-color: $color-red;
    float: left;

    &:not(:last-child) {
      margin-right: $gutter-horizontal;
    }
  }

  .col-1-of-2 {
    width: calc((100% - #{$gutter-horizontal}) / 2);
  }

  .col-1-of-3 {
    width: calc((100% - (#{$gutter-horizontal} * 2)) / 3);
  }

  .col-1-of-4 {
    width: calc((100% - (#{$gutter-horizontal} * 3)) / 4);
  }

  //   Col 2 of 3
  .col-2-of-3 {
    width: calc(2 * (100% - (#{$gutter-horizontal} * 2)) / 3);
  }

  .col-2-of-4 {
    width: calc(2 * (100% - (#{$gutter-horizontal} * 3)) / 4);
  }

  .col-3-of-4 {
    width: calc(3 * (100% - (#{$gutter-horizontal} * 3)) / 4);
  }
}
