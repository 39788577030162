// @import "../../sass/variables.scss";
@import "../../sass/main.scss";

.description {
  background-color: #fff;
  height: 100%;

  &__content {
    padding: 3vh 5vw;
    background-color: $color-primary;
    min-height: 50%;
    color: #fff !important;

    &-logo {
      font-weight: 700;
      color: #fff !important;
      font-size: 2.75rem !important;

      @media screen and (min-width: 360px) {
        margin-top: 10vh;
      }

      @media screen and (max-width: 360px) {
        margin-top: 2vh;
      }
    }

    &-title {
      margin-top: 0.25rem !important;
      font-weight: 600;
      font-size: 1.333rem !important;
      color: #fff !important;
    }
    &-description {
      margin-top: 2.75rem !important;
      font-weight: 500;
      font-size: 1.083rem !important;
      color: #fff !important;
    }
    &-version {
      font-size: 1.083rem !important;
    }
  }
}

.signin-form {
  padding: 3vh 5vw;
  background-color: #fff;

  &-title {
    margin-top: 1rem !important;
    font-weight: 600;
    font-size: 1.333rem !important;
  }
  @media screen and (min-width: 361px) {
    margin-top: 13vh;
  }

  @media screen and (max-width: 360px) {
    padding: 3vh 13vw;
  }

  &__item {
    margin-top: 2.5rem !important;
    font-size: 0.833rem !important;
  }
  &__input {
    // height: 30px;
    &::placeholder {
      font-style: italic !important;
    }
  }
}

.error {
  color: red;
}
