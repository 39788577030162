// @import "../../sass/variables.scss";
@import "../../sass/main.scss";
.app-header {
  background-color: $color-primary !important;
  height: auto;
}

.left-header {
  &-title {
    display: inline;
    color: #fff !important;
    vertical-align: middle;
    text-align: left;
    font-size: 1.333rem !important;
  }
}
.app-menu {
  background-color: $color-primary !important;
  color: #fff;
  font-size: 0.875rem;
  border-bottom: none;

  &__logo {
    color: antiquewhite !important;
  }

  &__item {
    font-size: 0.875rem !important;
    margin: 0 5px;
    background-color: $color-primary !important;
    border-radius: 5px;

    :global(.ant-menu-horizontal > .ant-menu-item a)
      :global(.ant-menu-horizontal:not(.ant-menu-dark)
        > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark)
        > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark)
        > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark)
        > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark)
        > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark)
        > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark)
        > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark)
        > .ant-menu-submenu-selected::after) {
      color: #fff !important;
      border-bottom: none !important;
    }

    &-text {
      font-size: 0.875rem !important;
      color: #fff !important;
    }
  }

  &__space {
    margin-left: 3rem;
  }
}

.app-submenu {
  // background-color: #354150 !important;

  &__item {
    font-size: 1.167rem !important;
    margin: 0 6rem;

    &-text {
      font-size: 1.167rem !important;
      color: $subMenu-text-color !important;
      font-weight: bold;
    }
  }
}

.avatar {
  background-color: #fff;
  color: $color-primary;
  &-icon {
    color: $hightlight !important;
  }
}

:global(.ant-menu-submenu-popup > .ant-menu) {
  background-color: $color-primary !important;
  border-radius: 5px;
  padding: 0;
}
